<template>
  <s-container large>
    <md-snackbar
      class="centered thick"
      md-position="center"
      :md-active.sync="showAnonymousReportConfirmation"
    >
      <span>
        <img :src="iconCheck" alt="" class="snackbar-icon" />
        {{ $t('REPORT_ANONYMOUS_SENT') }}
      </span>
    </md-snackbar>

    <md-snackbar
      class="centered thick"
      md-position="center"
      :md-active.sync="showCodeCopiedConfirmation"
    >
      <span>{{ $t('REPORT_ANONYMOUS_COPY_OK') }}</span>
    </md-snackbar>

    <div v-if="showInitialForm">
      <section>
        <h2 class="code-report__title my-20">
          {{ $t('COPY_CODE_TO_FOLLOW_ANONYMOUS_REPORT') }}
        </h2>
        <div class="code-report">
          <p class="code-report__text">
            {{ $t('ANONYMOUS_REPORT_CODE') }}
            <span class="code-report__text--bold">{{ code }}</span>
          </p>
          <s-button
            id="copy-code-report"
            btn-style="sea outline small"
            @click="copy"
          >
            {{ $t(copyText) }}
          </s-button>
        </div>
      </section>

      <section class="receive-sms__container">
        <p class="receive-sms__tag">
          <md-tooltip md-direction="bottom" class="tooltip">
            <p class="tooltip__description receive-sms">
              <strong>
                {{ $t('NOTIFICATION_CHANNEL_TOOLTIP_TITLE') }}
              </strong>
              <span>
                {{ $t('NOTIFICATION_CHANNEL_TOOLTIP_DESCRIPTION') }}
              </span>
            </p>
          </md-tooltip>
          <span>{{ $t('NEW') }}</span>
        </p>
        <h2 class="my-20">{{ $t('NOTIFICATION_CHANNEL_TITLE') }}</h2>
        <ul class="receive-sms__bullets">
          <li>{{ $t('NOTIFICATION_CHANNEL_SUBTITLE_1') }}</li>
          <li>
            {{ $t('NOTIFICATION_CHANNEL_SUBTITLE_2') }}
            <span class="receive-sms__bullet--bold">
              {{ $t('NOTIFICATION_CHANNEL_SUBTITLE_2_BOLD') }}
            </span>
          </li>
          <li>{{ $t('NOTIFICATION_CHANNEL_SUBTITLE_3') }}</li>
        </ul>

        <form @submit.prevent="subscribeToReport">
          <MaskedInput
            v-model="notification.mobilePhoneNumber"
            name="mobile-phone-number"
            class="receive-sms__phone-number"
            mask="\+11 (11) 11111-1111"
            placeholder="+ _ _ (_ _) _ _ _ _ _ - _ _ _ _"
            autocomplete="off"
            placeholder-char="0"
          />

          <md-radio
            id="sms"
            v-model="notification.channel"
            name="channel"
            value="SMS"
            class="mb-15"
          >
            <span class="radio-button__label">
              {{ $t('RECEIVE_CODE_BY_SMS_OPTION') }}
            </span>
          </md-radio>

          <md-radio
            id="whatsapp"
            v-model="notification.channel"
            name="channel"
            value="WHATSAPP"
            class="mb-15"
          >
            <span class="radio-button__label">
              <img :src="iconWhatsapp" alt="" class="snackbar-icon" />

              {{ $t('RECEIVE_CODE_BY_WHATSAPP_OPTION') }}
            </span>
          </md-radio>

          <s-button
            id="receive-sms-confirmation"
            type="submit"
            btn-style="primary"
            class="receive-sms__button"
            :disabled="isSubmitDisabled"
          >
            {{ $t('CONFIRM') }}
          </s-button>
        </form>
      </section>

      <section class="secury-disclaimer__toggle">
        <p>
          <router-link :to="{ name: 'company', params: { slug, slugId } }">
            {{ $t('DONT_RECEIVE_CODE_AND_NOTIFICATION_BY_SMS_OPTION') }}
            {{ $t('BACK_TO_HOME_PAGE') }}.
          </router-link>
        </p>

        <p
          class="secury-disclaimer__title"
          @click="toggleDisclaimer = !toggleDisclaimer"
        >
          {{ $t('FAQ_Q4') }}
        </p>
        <p v-if="toggleDisclaimer" class="secury-disclaimer__text">
          {{ $t('FAQ_Q4_P1') }}
        </p>
      </section>
    </div>

    <div v-else class="receive-sms__feedback">
      <CardMessage theme="eco" :icon="iconCheck">
        <div>
          <h2>{{ $t('SMS_SENT_TITLE') }}</h2>

          <p>{{ $t('SMS_SENT_SUB_TITLE') }}</p>

          <s-button btn-style="eco outline" @click.prevent="rootPage">
            {{ $t('SMS_SENT_HOME_LINK') }}
          </s-button>
        </div>
      </CardMessage>
    </div>
  </s-container>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import MaskedInput from 'vue-masked-input'
import { sanitizePhoneNumber } from '@/gateways/notification/sanitize-phone-number'
import CardMessage from '@/components/CardMessage'
import SButton from '@/components/ui/s-button'
import SContainer from '@/components/ui/s-container'
import iconCheck from '@/assets/images/icone_check_branco.svg'
import iconWhatsapp from '@/assets/images/whatsapp_icon.svg'

export default {
  name: 'ReportSubscribe',

  components: {
    CardMessage,
    MaskedInput,
    SButton,
    SContainer,
  },

  data() {
    const { slug, slugId } = this.$route.params

    return {
      notification: {
        mobilePhoneNumber: '',
        channel: null,
      },
      iconCheck,
      iconWhatsapp,
      code: '',
      copyText: 'copy',
      showInitialForm: true,
      showAnonymousReportConfirmation: false,
      showCodeCopiedConfirmation: false,
      toggleDisclaimer: false,
      slug,
      slugId,
    }
  },

  computed: {
    ...mapState([
      'locale',
      'company',
      'report',
      'notificationChannel',
      'error',
      'relationship',
    ]),

    isSubmitDisabled() {
      const { mobilePhoneNumber, channel } = this.notification

      return !mobilePhoneNumber || !channel
    },
  },

  mounted() {
    this.code = this.report?.code

    if (this.report) {
      this.showAnonymousReportConfirmation = true
    }

    if (!this.report) {
      this.rootPage()
    }
  },

  methods: {
    ...mapActions(['createReportNotificationChannel']),

    async subscribeToReport() {
      const { mobilePhoneNumber, channel } = this.notification
      const phoneNumber = sanitizePhoneNumber(mobilePhoneNumber, this.locale)

      const locale = {
        'pt-BR': 'pt-BR',
        pt: 'pt-BR',
        es: 'es-ES',
        en: 'en-EN',
      }[this.locale]

      const params = {
        reportId: this.report?.id,
        targetType: channel,
        target: `+${phoneNumber}`,
        locale,
        sendReportCode: true,
      }

      await this.createReportNotificationChannel(params)

      this.showInitialForm = false
    },

    rootPage() {
      const { slug, slugId } = this.company || {}

      this.$router.push({ name: 'company', params: { slug, slugId } })
    },

    copy() {
      navigator.clipboard.writeText(this.code)
      this.copyText = 'copied'
      this.showCodeCopiedConfirmation = true
    },
  },
}
</script>

<style scoped>
.code-report {
  display: flex;
  align-items: flex-end;
}

.code-report__text {
  line-height: 24px;
  margin: 0 20px 0 0;
}

.code-report__text--bold {
  display: block;
  font-size: 20px;
  font-weight: 500;
}

.code-report__title {
  color: var(--color-blue-default);
}

.receive-sms__container {
  margin-top: 50px;
}

.receive-sms__bullets {
  font-size: 16px;
  list-style-type: disc;
  padding-left: 20px;
}

.receive-sms__bullets li {
  margin-bottom: 5px;
}

.receive-sms__bullet--bold {
  font-weight: bold;
}

.receive-sms__phone-number {
  width: 70%;
  border: 2px solid var(--color-grey-default);
  margin: 24px 0;
}

.receive-sms__button {
  margin: 20px 0;
}

.radio-button__label {
  font-size: 14px;
}

.restart-sms__button {
  background: transparent;
  border: none;
  box-shadow: none;
  color: var(--color-green-default);
  font-size: 16px;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 19px;
  text-align: center;
  text-decoration: underline;
  text-transform: none;
  width: 100%;
}
.secury-disclaimer__title {
  color: var(--color-grey-lighter);
  cursor: pointer;
  font-size: 14px;
  text-decoration: underline;
}

.secury-disclaimer__text {
  color: var(--color-grey-lighter);
  font-size: 14px;
  margin-bottom: 40px;
}

.snackbar-icon {
  height: 20px;
  width: 20px;
  margin-right: 5px;
  vertical-align: text-bottom;
}

.my-20 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.receive-sms__tag {
  background-color: var(--color-blue-light);
  color: var(--color-blue-default);
  text-transform: uppercase;
  font-weight: 600;
  font-size: 13px;
  width: fit-content;
  padding: 5px 15px;
  border-radius: 20px;
}

.tooltip__description.receive-sms {
  margin: 0;
}

.tooltip__description.receive-sms strong {
  display: block;
}

.agree-adjust {
  margin-left: -15px;
}

.mb-15 {
  margin-bottom: 15px;
}
</style>
