const PHONE_CONFIGURATIONS = {
  'pt-BR': {
    countryCodeLength: 2,
    regionCodeLength: 2,
    prefixLength: 5,
    suffixLength: 4,
  },
  en: {
    countryCodeLength: 2,
    regionCodeLength: 2,
    prefixLength: 5,
    suffixLength: 4,
  },
  es: {
    countryCodeLength: 2,
    regionCodeLength: 2,
    prefixLength: 5,
    suffixLength: 4,
  },
}

const sumTotalDigitsByLang = (lang) => {
  const sanitizedLang = lang === 'pt' ? 'pt-BR' : lang
  const configByLang = PHONE_CONFIGURATIONS[sanitizedLang]

  if (!configByLang) {
    throw new Error(`Lang ${lang} not found. Please provide a valid phone lang`)
  }

  return Object.values(configByLang).reduce((acc, cur) => acc + cur, 0)
}

export const sanitizePhoneNumber = (number, lang) => {
  const onlyDigits = number.replace(/\D/g, '')
  const isValid = onlyDigits.length === sumTotalDigitsByLang(lang)

  if (isValid) {
    return onlyDigits
  }

  throw new Error(`Invalid phone number length of ${number} for ${lang}`)
}
